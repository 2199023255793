
import ManagementMixin from '@/mixins/ManagementMixin';
import RoleTab from '@/components/Management/User/RoleTab.vue';
import { mapState } from 'vuex';

export default {
  components: { RoleTab },
  mixins: [ManagementMixin],
  data() {
    return {
      tabOptions: [],
      drawerShown: false,
      routeName: ''
    };
  },
  created() {},
  methods: {
    openDrawer() {
      this.drawerShown = true;
    },
    closeDrawer() {
      this.drawerShown = false;
    }
  }
};
